import React from 'react';
import '../styles/App.scss';

function Contact() {
  return (
    <div className="footer-section">
    <h3>Contact Us</h3>
    <ul className="contact-list">
        <li>
            <i className="material-icons">phone</i>
            <a href="tel:2043392675">204-339-2675</a>
        </li>
        <li>
            <i className="material-icons">email</i>
            <a href="mailto:drfernandesdentalclinic@gmail.com">Email</a>
        </li>
        <li>
            <i className="material-icons">map</i>
            <a href="https://www.google.com/maps/dir//Fernandes+A+Dr,+2055+McPhillips+St,+Winnipeg,+MB+R2V+3C6/@49.9448892,-97.1626708,15z/data=!4m16!1m6!3m5!1s0x0:0xe1115fc68102a640!2sFernandes+A+Dr!8m2!3d49.9448063!4d-97.1547744!4m8!1m0!1m5!1m1!1s0x52ea71f380c863a1:0xe1115fc68102a640!2m2!1d-97.1547744!2d49.9448063!3e2">Get Directions</a>
        </li>
        <li>
                2055 McPhillips Street 
                <br />
                Winnipeg, Manitoba 
                <br /> 
                R2V 3C6
        </li>
        </ul>
    </div>  
    );
}

export default Contact;

