import React from 'react';
import '../styles/App.scss';

function Hours() {
  return (
        <div className="footer-section">
            <h3>Our Hours</h3>
            <table className="hours">
                <tr> 
                    <th>Mon:</th>  
                    <td>9am–5pm</td>  
                </tr> 
                <tr> 
                    <th>Tue:</th>  
                    <td>9am–5pm</td>  
                </tr> 
                <tr> 
                    <th>Wed:</th>  
                    <td>9am–5pm</td>  
                </tr> 
                <tr> 
                    <th>Thu:</th>  
                    <td>9am–5pm</td>  
                </tr> 
                <tr> 
                    <th>Fri:</th>  
                    <td>Closed</td>  
                </tr> 
            </table>
        </div>
  );
}

export default Hours;

