import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import QuickNav from "./Header/QuickNav"
import Header from "./Header/header";
import ColorBar from "./Header/ColorBar"
import Footer from "./Footer/Footer"
import CopyrightBar from "./Footer/CopyrightBar"
import TopButton from "./Header/TopButton"
import "./styles/layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="app-container" id="home">
      <ColorBar />
      <QuickNav />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <Footer />
        <CopyrightBar />
        <TopButton />
      </div>
      <ColorBar />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
