import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Link } from 'gatsby'
import "../styles/App.scss"

function NavbarLinks(props) {
  return (
    <div className={'nav-bar ' + props.grow}>
      <h1 className="main-header">
        <AnchorLink offset={props.offset} id="header-font" href="#home">Dr. Americo Fernandes <span>Family Dentistry</span></AnchorLink>
      </h1>
      <div className="nav-bar-links">  
        <AnchorLink offset={props.offset} className="nav-bar-link" href="#services">
          Services
        </AnchorLink>
        <AnchorLink offset={props.offset}  className="nav-bar-link" href="#implants">
          Implants
        </AnchorLink>
        <AnchorLink offset={props.offset} className="nav-bar-link" href="#about">
          About
        </AnchorLink>
        <AnchorLink offset={props.offset} className="nav-bar-link" href="#contact">
          Contact
        </AnchorLink>
      </div>
      <AnchorLink offset={props.offset} className="inquire-button-wrapper" href="#email">
          <button className="inquire-button">Inquire</button>
        </AnchorLink>
    </div>
  )
}

export default NavbarLinks
