import React from 'react';
import '../styles/App.scss';
import Hours from './Hours'
import Contact from './Contact'

function Footer() {
  return (
    <footer className="footer">
      <Hours />
      <Contact />
    </footer>
  );
}

export default Footer;

