import React from "react";
import "../styles/App.scss";

class EmailForm extends React.Component {
  render() {
    return (
      <div className="email-form-wrapper" id="email">
        <h1 className="email-title">Email to Inquire*</h1>
        <form name="office" action="/#email" method="POST" data-netlify="true">
          <input type="hidden" name="office" value="office" />
          <div className="fields">
            <div className="field half">
              <input type="text" name="name" id="name" placeholder="Name" />
            </div>
            <div className="field half">
              <input type="email" name="email" id="email" placeholder="Email" />
            </div>
            <div className="field">
              <textarea
                name="message"
                id="message"
                placeholder="Message"
                rows="7"
              ></textarea>
            </div>
          </div>
          {/* <div className="field">
            <div data-netlify-recaptcha="true"></div>
          </div> */}
          <input
            type="submit"
            value="Send Message"
            className="button primary"
            id="send-message-button"
          />
        </form>
        <p className="fine-print">
          * We do not make appointments via email. If you would like to make an
          appointment, please call us at
          <a style={{marginLeft: '5px'}} href="tel:2043392675">(204) 339-2675</a>.
        </p>
      </div>
    );
  }
}

export default EmailForm;
