import React from 'react';
import '../styles/App.scss';


function Copyright() {
  return (
    <div className="copyright-bar">
      © Dr. Americo Fernandes Family Dentistry 2019
    </div>
  );
}

export default Copyright;
