import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import "../styles/App.scss"

class TopButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showButton: false }
    this.showHiddenButton = this.showHiddenButton.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.showHiddenButton)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.showHiddenButton)
  }

  showHiddenButton() {
    if (window.scrollY >= 700) {
      this.setState({ showButton: true })
    }
    if (window.scrollY < 700) {
      this.setState({ showButton: false })
    }
  }

  render() {
    const showButton = this.state.showButton
    let button

    if (showButton) {
      button = (
        <AnchorLink href="#home">
          <button className="" id="top-button" title="Go to top">
            <i className="material-icons">arrow_upward</i>
          </button>
        </AnchorLink>
      )
    } else {
      button = (
        <AnchorLink href="#home">
          <button
            className="button primary button-hidden"
            id="top-button"
            title="Go to top"
            hidden
          >
            <i className="material-icons">arrow_upward</i>
          </button>
        </AnchorLink>
      )
    }
    return button
  }
}

export default TopButton
