import React from "react"
import Navbar from "./navbar"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { offset: 0, grow: "grow" };
    this.scrollFunction = this.scrollFunction.bind(this);
  }

  componentDidMount() {
    this.setState({ offset: 100});
    window.addEventListener("scroll", this.scrollFunction);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFunction);
  }

  scrollFunction(e) {
    if(window.scrollY >= 130){
      this.setState({grow: ""})
    } else {
      this.setState({grow: "grow"})
    }
  }

  render() {
    return <Navbar offset={this.state.offset} grow={this.state.grow} />
  }
}

export default Header
